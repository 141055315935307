import {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import {computed} from 'vue';
import {ThemeStore} from "@/Stores/index.js";

const isDarkMode = computed(() => {
    return ThemeStore.isDark();
});

function success(message) {
    toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: isDarkMode.value ? 'dark' : 'light',
    });
}

function error(message) {
    toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: isDarkMode.value ? 'dark' : 'light',
    });
}

function info(message, settings) {
    toast.info(message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: isDarkMode.value ? 'dark' : 'light',
        ...settings,
    });
}

export default {
    success,
    error,
    info,
};
