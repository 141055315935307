import './bootstrap';
import '../css/portal.css';
import 'floating-vue/dist/style.css';

import {createApp, h} from 'vue';
import {createInertiaApp, router} from '@inertiajs/vue3';
import {ZiggyVue} from 'ziggy-js';
import {__, formatDate, formatDateTime, localEnvHelper, renderDebugMessage, Toaster} from "@/Utils";
import {Portal} from "@/Layouts";
import setDefaultOptions from 'date-fns/setDefaultOptions'
import {ro} from 'date-fns/locale'
import {autoAnimatePlugin} from '@formkit/auto-animate/vue'
import {HelpStore} from "@/Stores/index.js";
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import VueSplide from "@splidejs/vue-splide";
import VueRewards from 'vue-rewards';
import FloatingVue from 'floating-vue';
import './Frontend/matchHeight.js';
import {createPinia} from 'pinia';
import {useAbilities} from "@/Utils/index.js";


const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

import.meta.glob([
    '../fonts/**',
]);

function renderSessionToaster(props) {
    if (props.success) {
        Toaster.success(props.success);
        delete props.success;
    }

    if (props.info) {
        Toaster.info(props.info);
        delete props.info;
    }

    if (props.error) {
        Toaster.error(props.error);
        delete props.error;
    }
}

function loadHelp(page) {
    const glob = import.meta.glob("./Pages/**/*.help.js")

    const tryFiles = [];

    if (page.props.auth?.account?.is_business_account) {
        tryFiles.push(`./Pages/${page.component}.business.help.js`);
    } else {
        tryFiles.push(`./Pages/${page.component}.individual.help.js`);
    }

    tryFiles.push(`./Pages/${page.component}.help.js`)

    let file = null;
    for (const filePath of tryFiles) {
        file = glob[filePath];
        if (file) {
            break;
        }
    }

    if (!file) {
        HelpStore.setSteps([]);
        return
    }

    file().then((helpArray) => {
        const {hasAbility} = useAbilities();

        if (!helpArray.default) {
            HelpStore.setSteps([]);
            return;
        }

        const filteredHelpArray = helpArray.default.filter((step) => {
            if (!step.abilities || step.abilities.length === 0) {
                return true;
            }

            return Array.isArray(step.abilities) ? step.abilities.some(ability => hasAbility(ability)) : hasAbility(step.abilities);
        });
        HelpStore.setSteps(filteredHelpArray);
    });
}

document.getElementById('app').classList.add('min-h-screen');

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: async (name) => {
        const pages = import.meta.glob('./Pages/**/*.vue');

        let component = pages[`./Pages/${name}.vue`];

        if (!component) {
            component = pages[`./Pages/404.vue`];
        }

        const page = await component();

        page.default.layout = page.default.layout || Portal;
        return page.default;
    },
    setup({el, App, props, plugin}) {
        renderSessionToaster(props.initialPage.props);
        renderDebugMessage(props.initialPage);
        loadHelp(props.initialPage);

        if (props.initialPage.props.enable_test_accounts) {
            localEnvHelper();
        }

        return createApp({render: () => h(App, props)})
            .use(plugin)
            .use(ZiggyVue)
            .use(autoAnimatePlugin)
            .use(VueTippy)
            .use(VueSplide)
            .use(VueRewards)
            .use(FloatingVue)
            .use(createPinia())

            .mixin({
                methods: {
                    __,
                    formatDate,
                    formatDateTime
                }
            })
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});


router.on("error", event => {
    Toaster.error(__("Something went wrong. Please check the form for errors and try again."));
});

router.on("success", event => {
    renderSessionToaster(event.detail.page.props);
    renderDebugMessage(event.detail.page);
    loadHelp(event.detail.page);
});

setDefaultOptions({locale: ro})

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (error?.response?.headers?.precognition) {
        return Promise.reject(error);
    }

    if (error?.response?.data?.message) {
        Toaster.error(error.response.data.message);
    }

    return Promise.reject(error);
});
