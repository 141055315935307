<script setup>
import MobileSidebar from "@/Layouts/Partials/Portal/MobileSidebar.vue";
import DesktopSidebar from "@/Layouts/Partials/Portal/DesktopSidebar.vue";
import PageHeader from "@/Layouts/Partials/Portal/PageHeader.vue";
import {router, usePage} from "@inertiajs/vue3";
import {onKeyDown} from "@vueuse/core";
import {__, createConfirmDialog} from "../Utils/index.js";
import LoginAsModal from "./Partials/LoginAsModal.vue";
import {DialogsWrapper} from 'vuejs-confirm-dialog'
import {SlideFromLeft} from "../Components/Transitions/index.js";
import {LoadingIndicator} from "../Components/Common/index.js";
import {onMounted, onUnmounted, ref} from "vue";
import TokenUsageWarningModal from "@/Layouts/Partials/Portal/TokenUsageWarningModal.vue";
import {PageTransition} from "@/Components/Transitions/index.js";
import Toaster from "@/Utils/Toaster.js";
import {route} from "ziggy-js";
import HumanInterventionToast from "@/Components/Common/HumanInterventionToast.vue";
import emitter from "@/Utils/eventBus.js";

defineProps({
    innerPadding: {
        type: Boolean,
        default: true
    },
    animations: {
        type: Boolean,
        default: true
    },
    breadcrumbs: {
        type: Boolean,
        default: true
    }
})

const page = usePage();
const loading = ref(false);

if (page.props.auth.role === 'super_admin' || page.props.auth.isImpersonating) {
    onKeyDown('l', (event) => {
        if (event.ctrlKey) {
            event.preventDefault();
            const page = usePage();

            if (page.props.impersonating) {
                router.visit(route('portal.api.users.impersonate.stop'));
                return;
            }

            const {onConfirm} = createConfirmDialog(LoginAsModal,
                {
                    title: __('Login as'),
                });

            onConfirm((user) => {
                if (user) {
                    router.post(route('portal.api.users.impersonate'), {
                        user: user.id
                    });
                }
            });
        }
    });
}
let broadcastChannel = null;

const show = ref(false);
onMounted(async () => {
    requestAnimationFrame(() => {
        show.value = true;
    });

    broadcastChannel = window.Echo.private(`website-conversation-required-human-intervention.${page.props.auth.id}`)
    broadcastChannel.listen(".App\\Events\\WebsiteConversationRequiredHumanIntervention", (data) => {
        emitter.emit('newConversationRequiredHumanIntervention', {
            conversationUuid: data.conversationUuid,
        });
        Toaster.info(HumanInterventionToast, {
            autoClose: 9000,
            contentProps: {
                title: __('A conversation requires your attention'),
                onClick: () => {
                    router.visit(route('portal.chat', {conversation: data.conversationUuid}));
                }
            },
            style: {
                width: '400px',
            }
        });
    })
});

onUnmounted(() => {
    broadcastChannel.stopListening(".App\\Events\\WebsiteConversationRequiredHumanIntervention");
    window.Echo.leaveChannel(`website-conversation-required-human-intervention.${page.props.auth.id}`);
})

</script>

<template>
    <div class="min-h-screen bg-angular-gradient dark:bg-angular-gardient-dark">
        <DialogsWrapper/>
        <MobileSidebar/>
        <DesktopSidebar/>
        <TokenUsageWarningModal/>
        <div class="flex flex-col min-h-screen duration-100 lg:pl-72">
            <PageHeader v-if="breadcrumbs">
                <slot name="header">
                    <div id="page-header"></div>
                </slot>
            </PageHeader>

            <main :class="{'py-2': innerPadding}" class="flex-1 flex flex-col">
                <div :class="{'mx-2 sm:mx-4 lg:mx-6': innerPadding}" class="flex-1 relative">
                    <PageTransition v-if="animations">
                        <div :key="$page.url">
                            <slot/>
                        </div>
                    </PageTransition>
                    <div v-else>
                        <slot/>
                    </div>
                </div>
            </main>
        </div>
    </div>

    <SlideFromLeft>
        <div v-if="loading"
             class="fixed inset-0 bg-black/10 backdrop-blur-[1px] z-[999] flex items-center justify-center">
            <LoadingIndicator size="size-20"/>
        </div>
    </SlideFromLeft>
</template>
