<script setup>

import {onBeforeMount, ref} from "vue";
import {router, usePage} from "@inertiajs/vue3";
import dayjs from "dayjs";

import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {LoadingIndicator} from "@/Components/Common/index.js";
import {route} from "ziggy-js";
import {usePermissions} from "@/Utils";

dayjs.extend(timezone);
dayjs.extend(utc);
const {hasGlobalPermission} = usePermissions();

const props = defineProps({
    label: {
        type: String,
        required: true,
    },
    timezone: {
        type: String,
        required: true,
    },
    startAt: {
        type: String,
        required: true,
    },
    endAt: {
        type: String,
        required: true,
    }
});

const page = usePage();
const currentTimeZone = page.props.timezone;
const startDate = dayjs.utc(props.startAt).tz(currentTimeZone);
const endDate = dayjs.utc(props.endAt).tz(currentTimeZone);

const displayDays = ref('00');
const displayHours = ref('00');
const displayMinutes = ref('00');
const displaySeconds = ref('00');
const displayPercentage = ref(0);

const seconds = 1000;
const minutes = seconds * 60;
const hours = minutes * 60;
const days = hours * 24;

const loading = ref(true);
const timerIsOver = ref(false);

const formatNumber = (number) => number < 10 ? `0${number}` : number;

const calculateTime = () => {
    const today = dayjs().tz(currentTimeZone);

    const distance = endDate - today;

    if (distance < 0) {
        displayDays.value = '00';
        displayHours.value = '00';
        displayMinutes.value = '00';
        displaySeconds.value = '00';
        timerIsOver.value = true;
        loading.value = false;
        return;
    }

    const totalDistance = endDate - startDate;
    const percentage = (distance / totalDistance) * 100;

    const remainingDays = Math.floor(distance / days);
    const remainingHours = Math.floor((distance % days) / hours);
    const remainingMinutes = Math.floor((distance % hours) / minutes);
    const remainingSeconds = Math.floor((distance % minutes) / seconds);

    displayDays.value = formatNumber(remainingDays);
    displayHours.value = formatNumber(remainingHours);
    displayMinutes.value = formatNumber(remainingMinutes);
    displaySeconds.value = formatNumber(remainingSeconds);

    displayPercentage.value = Math.max(0, Math.min(100, percentage.toFixed(2)));

    if (loading.value) {
        loading.value = false;
    }
};

const authCanChangeSubscription = hasGlobalPermission('paymentPlan.subscriptionCheckout');

function manageSubscription() {
    if (authCanChangeSubscription) {
        router.visit(route('portal.payment-plans.choose'));
    }
}

onBeforeMount(() => {
    const timer = setInterval(() => {
        calculateTime();
        if (timerIsOver.value) {
            clearInterval(timer);
        }
    }, 1000);
});

</script>

<template>
    <div class="mb-5" v-if="!timerIsOver"
         :class="{'hover:cursor-pointer': authCanChangeSubscription}"
         @click="manageSubscription">
        <div :class="[loading ? 'flex items-center' : 'flex flex-col' ,'mb-2']" v-if="!timerIsOver">
            <div class="text-gray-900 dark:text-slate-200 text-base mb-1 font-semibold">
                {{ label }}
            </div>

            <LoadingIndicator v-if="loading" class="mb-1.5 ml-5"/>
            <div class="text-gray-900 dark:text-slate-200 text-xs" v-if="!loading">
                {{
                    displayDays + __(' days ') + displayHours + __(' hours ') + displayMinutes + __(' minutes ') + displaySeconds + __(' seconds ')
                }}
            </div>
        </div>
        <div class="bg-gray-100 dark:bg-gray-900 w-full overflow-hidden rounded-full" v-if="!loading && !timerIsOver">
            <div class="bg-purple-600 h-3" :style="`width: ${displayPercentage}%`"/>
        </div>
    </div>
</template>
